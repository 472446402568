@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,300;1,400&display=swap');
.cuerpo-content{
}
.fa-check-circle{
    font-size: 60px;
    color: #00224f;
}
.fa-google-play{
    font-size: 40px;
    color: white;
}
.fa-app-store{
    font-size: 40px;
    color:white
}
.fa-syringe{
    font-size: 60px;
    color: #00224f;
}
.img-ventajas{
    width: 7em;
}
.img-programmer{
filter:hue-rotate(192deg) brightness(1.18) grayscale(0.5);
}
.img-slader{
    width: 7em;
    filter: contrast(.9)
}
.fa-dollar-sign{
    font-size: 60px;
    color: #00224f;
}
.fa-user-circle{
    font-size:70px;
}
.fa-clipboard-list{
    font-size: 70px;

}
.z-index-1-relative{
    z-index: 1;
    position: relative;
}
.fa-wifi{
    font-size: 41px;
}
.div-img-2{

}
.fa-calendar-alt{
    font-size: 70px;
}
.p-ventajas{
    font-size: 24px;
    margin-top: 0.5em;
    font-family:'Poppins', sans-serif;
}
.content-ventajas{
    width: 18em;
    margin: 1em auto;
    border-radius: .5em;
    text-align: center;
    padding: 1.5em;
    -webkit-box-shadow: -1px 0px 19px -5px rgb(0 0 0 / 19%);
    -moz-box-shadow: -1px 0px 19px -5px rgba(0,0,0,0.19);
    box-shadow: -1px 0px 19px -5px rgb(0 0 0 / 19%);
}
.background-principal{
    position: relative;
    height: 38em;
    background-size: 100% 100%;
    background-image: url('../../static/img/fondo-principal.png');
}

.inner-column{
    height: 28em;
    padding-top: 3em;
}

.image-shape-22{
    width: 12em !important;
    background-image:url('../../static/img/shape-22.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100% 100%;
    position: absolute;
    height: 20em;
    right: 3em;
    transform: rotate(200deg);
    bottom: -1.5em;
}
.navbar{
    -webkit-box-shadow: -2px 11px 32px -16px rgba(0,0,0,0.46);
    -moz-box-shadow: -2px 11px 32px -16px rgba(0,0,0,0.46);
    box-shadow: -2px 11px 32px -16px rgba(0,0,0,0.46);
}
.image-shape-21{
    width: 12em !important;
    background-image:url('../../static/img/shape-21.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100% 100%;
    position: absolute;
    height: 20em;
    right: 3em;
    transform: rotate(15deg);
    bottom: -1.5em;
    z-index:1;
}

.image-shape-21-2{
    width: 12em !important;
    background-image:url('../../static/img/shape-21.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100% 100%;
    position: absolute;
    height: 20em;
    left: 3em;
    transform: rotate(
            207deg);
    top: -2.5em;
    z-index:1;
}
.image-shape-21-2-1{
    width: 12em !important;
    background-image:url('../../static/img/shape-21.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100% 100%;
    position: absolute;
    height: 20em;
    right: 3em;
    transform: rotate(15deg);
    bottom: -1.5em;

}

.image-shape-21-2-2{
    width: 12em !important;
    background-image:url('../../static/img/shape-21.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100% 100%;
    position: absolute;
    height: 20em;
    left: 3em;
    transform: rotate(
            207deg);
    top: -5.5em;
}
.image-shape-21-2-3{
    width: 12em !important;
    background-image: url('../../static/img/shape-21.png');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100% 100%;
    position: absolute;
    height: 20em;
    right: 17em;
    transform: rotate(
            207deg);
    top: -2.5em;
}
.p-icon{
    color: white;
}
.video-one{
    background-image: url("../../static/img/hqdefault.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;

}
.video-two{
    background-image: url("../../static/img/hqdefault (1).webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.video-three{
    background-image: url("../../static/img/hqdefault (2).webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.about-section {
    position: relative;
    padding: 120px 0px;


}

.about-section .about-inner {
    position: relative;
    background: #fff;
    margin: 0 20px 0 20px;
    padding: 63px 150px 70px 70px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0px 30px rgb(0 24 73 / 10%);
}

.about-section .pattern-layer .pattern-1 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 239px;
    height: 376px;
    background-repeat: no-repeat;
}

.about-section .bg-layer {
    position: absolute;
    content: '';
    width: 50%;
    height: 100%;
    top: 0px;
    right: 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.p-slide{
    margin-top:.5em;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}
.bg-layer{
    background-image: url('../../static/img/about-1.jpg')
}
.pattern-1{
    background-image: url('../../static/img/shape-22.png')
}


.bg-color-3 {
    background: #ebf7f6;
}
@media ( max-width: 767px){
    .banner-image{
        width: 80%;
        margin: 0px auto;
    }
    .title-init{
        font-size: 43px;
    }
}
.jost-title{
    font-family: 'Jost';
    font-weight: 900;
    font-size: 30px;
}
.img-calendar{
    width: 6em !important;
}
.img-wifi{
    width: 4em !important;
}
.shape-img-tutoriales-1{
    position:absolute;
    background-image:url('../../static/img/shape-29.png');
    width: 25em !important;
    right: 0em;
    height: 14em;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 0em;
}
.shape-img-tutoriales-2{
    background-image:url('../../static/img/shape-29.png');
    position: absolute;
    width: 28em !important;
    height: 12em;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.row-img{
    position: relative;
    bottom: 2.5em;
    height: 122px;
}
.img-slader-2{
    width: 15em;
    position: relative;
    bottom: 5em;
}
.p-slide-2{
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
}
@media ( max-width: 535px){
    .img-slader-2{
        width: 9em;
        bottom: -1em;
        right: 2em;
    }
    .p-slide-2{
        font-size: 15px;
    }
    .jost-title {
        font-size: 24px;
    }
}
@media ( max-width: 760px ) and (min-width: 535px){
    .img-slader-2{
        width: 12em;
        position: relative;
        bottom: 2em;
    }
    .p-slide-2{
        font-size: 17px;
        font-family: 'Poppins', sans-serif;
    }
}
@media ( max-width: 460px){
    .img-calendar{
        width: 3.1em !important;
    }
    .row-img{
        bottom: 1.0em;
        height: 56px;
    }
    .p-slide{
        font-size: 13px;
    }
    .jost-title{
        font-size:21px;
    }
    .img-wifi{
        width: 1.5em !important;
    }
    .img-slader{
        width:3.5em ;
    }
    .about-inner{
        padding: 59px 31px 70px 34px !important;
    }
}
@media ( max-width: 992px){
    .background-principal{
        height:max-content;
    }
}

a {
    text-decoration: none;
}

@media only screen and (max-width: 1200px) {
    .pattern-layer {
        display: none !important;
    }
}
.pattern-2{
    background-image: url('../../static/img/shape-22.png');
}

.banner-image-00{
    width: 30em;
    position: absolute;
    height: 20em;
    right: 0px;
    z-index: 0;
    top: 2em;
}
