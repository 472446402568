@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,700;0,800;0,900;1,300;1,400;1,500;1,700;1,800;1,900&family=Poppins:ital,wght@0,400;0,500;1,300;1,400&display=swap');
.form-contact{
    width: 100%;
    height:max-content;
    background-color:transparent;
}
.carta-contact{
    padding: 1em;
    position: relative;
    width: max-content;
    height: max-content;
    margin: 1em auto;
    background:white;
    border-radius: 6px;
}
.form-left{
    margin-right:.5em !important;
}

.title-init-container{
    width: 100%;
    /*display: inline-block;*/
    /* text-align: center; */
    margin: 0em auto 0 auto;
    padding-left: 2em;

}
.title-init{
    font-size: 65px;
    font-family: 'Jost', sans-serif;
    color:#04224f;
    font-weight: 900;
}
.title-init-h1{
    font-family: 'Jost', sans-serif;
    margin: 0;
    color: #00224f;
    -webkit-text-stroke: 3px;
    font-size: 75px;;
    display: inline;
}
.pattern-1{
    background-image: url('../../static/img/shape-15.png');
}

.pattern-3{
    background-image: url('../../static/img/shape-16.png');
}
/* Contact */

.contact-section {
    position: relative;
    position: relative;
    background: #fff;
    margin: 0 20px 0 20px;
    padding: 37px 0px 120px 0px;
}
img {
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: auto;
    z-index: 2;
}
.image_block_1 .image-box:before {
    position: absolute;
    content: '';
    width: 400px;
    height: 400px;
    border-radius: 50%;
    left: 5px;
    top: 150px;
}
.image_block_1 .image-box {
    position: relative;
    display: block;
    max-width: 570px;
}
.image_block_1 .image-box .pattern .pattern-1 {
    position: absolute;
    left: -9em;
    top: 63px;
    width: 560px;
    height: 491px;
    z-index: 1;
    background-repeat: no-repeat;
}
.image_block_1 .image-box .pattern .pattern-2 {
    position: absolute;
    left: 0px;
    top: 170px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    opacity: 0.7;
    -webkit-animation: zoom-fade 3s infinite linear;
    animation: zoom-fade 3s infinite linear;
}

.image_block_1 .image-box .pattern .pattern-2 {
    background: #056E93;
}
.contact-form-contatiner {
    border: 1px solid grey;
    border-radius: 10px;
    padding: 30px;
    max-width: 411px;
    margin: 0 auto;
}


@media (min-width: 603px){
    .contact-form-contatiner {
        border: 1px solid grey;
        border-radius: 10px;
        padding: 30px;
        max-width: 36em;
        margin: 0 auto;
    }
}

@media (max-width: 603px){
    .form-left {
        margin-right: 0px !important;
        margin-bottom: .5em !important;

    }
}
.icon-init{
    font-size: 17em;
    color: limegreen;
    top: 72px;
    right: 115px;
    display:inline;
}

