.col-landing-header{
  height:3.5em;
  margin: 0;
}
.col-landing-content{
  height: max-content;
}
.col-landing-footer{
  background-color: #453f85
}
.landing-container{
  height: 100vh;
  width: 100% !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {display: none;}
.nav-item{
  margin: auto 0;
}
.nav-link{
  font-size: 17px;
  font-weight: 500;
}
.nav-link:hover{
  border-bottom: 2px solid lightblue;
}
p{
  color:#04224f;
}
